import React from 'react'

import { Caption1, Heading3, MicroText1 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import QueryLoader from '~/components/QueryLoader'
import StreakCalendar from '~/components/streaks/StreakCalendar'
import SkeletonLoader from '~/components/ui/SkeletonLoader'
import useStreakData from '~/hooks/useStreakData'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const StreakCard = (): JSX.Element => {
  const t = useTranslationForNamespace('common')
  const { currentStreakLength, bestStreakLength, loading, error } = useStreakData()

  return (
    <QueryLoader loading={loading} error={error} data={!loading} LoadingComponent={<Skeleton />}>
      <div className='w-full shadow-drop-shadow-1 border border-border-subdued rounded-lg bg-surface-default'>
        <div className='p-4 pl-6 flex flex-row items-center justify-between border-b border-border-subdued'>
          <MicroText1 className='text-text-subdued'>{t('streaks.yourStreaks')}</MicroText1>
        </div>
        <div className='p-4 pt-8 pb-10 flex flex-col items-center '>
          <div className='text-text-default'>
            <StreakCalendar inkBackground={false} />
          </div>
          <div className='mt-6 flex flex-row justify-between items-center gap-12'>
            <div className='flex flex-col items-center'>
              <Heading3 className='mb-1'>{t('streaks.day', { count: currentStreakLength })}</Heading3>
              <Caption1 className='text-text-subdued'>{t('streaks.currentStreak')}</Caption1>
            </div>
            <div className='flex flex-col items-center'>
              <Heading3 className='mb-1'>{t('streaks.day', { count: bestStreakLength })}</Heading3>
              <Caption1 className='text-text-subdued'>{t('streaks.bestStreak')}</Caption1>
            </div>
          </div>
        </div>
      </div>
    </QueryLoader>
  )
}

const Skeleton = styled(SkeletonLoader)`
  width: 448px;
  height: 240px;
`

export default StreakCard
