import React, { useContext } from 'react'

import styled, { css } from 'styled-components'

import { AuthContext } from '~/context/AuthContext'
import { WorkoutMetaNotNull } from '~/hooks/useTodaysWorkout'
import { WorkoutModeIcon } from '~/images/Workout/Mode'
import WorkoutComplete from '~/images/Workout/WorkoutCompleteYellow.svg'
import WorkoutSegmentSvg from '~/images/Workout/WorkoutSegment.svg'
import { nonTokenColors } from '~/styles/color'

interface WorkoutRingProps {
  workoutMeta: WorkoutMetaNotNull
  slug?: string // if slug is passed, treat that as active ring, i.e. used in game screens, mainly used in postGame screen
  checkmarkDelay?: number
}

type WorkoutSegmentStatus = 'Inactive' | 'Active' | 'Complete' | 'Locked'
type RingStatusType = Record<WorkoutSegmentStatus, { code: WorkoutSegmentStatus; color: string; className: string }>

export const WorkoutRingStatus: RingStatusType = {
  Inactive: { code: 'Inactive', color: nonTokenColors.neutralWarn95, className: 'fadeInAnimate' },
  Active: { code: 'Active', color: nonTokenColors.neutralWarn95, className: 'blinkActiveAnimate' },
  Complete: { code: 'Complete', color: nonTokenColors.accentSecondary60, className: 'fadeInAnimate' },
  Locked: { code: 'Locked', color: nonTokenColors.neutralWarm85, className: 'fadeInAnimate' },
} as const

const WorkoutRing: React.FC<WorkoutRingProps> = ({ workoutMeta, slug }) => {
  const { hasPremium } = useContext(AuthContext)

  const { allPremiumSlots, isWorkoutComplete, mode } = workoutMeta

  let lastUnplayedGame = false // helps in determining the active ring
  const mappedSlots = allPremiumSlots.map((slot: any, index: number) => {
    const status = (() => {
      // if the slug matches, consider that step as active
      if (slug === slot.game.slug) {
        lastUnplayedGame = true // only 1 active ring at a time
        // if the game is already played, consider it as complete
        if (slot.lastGamePlay) return WorkoutRingStatus.Complete.code
        return WorkoutRingStatus.Active.code
      }

      // 3rd & 4th slot is locked for free users
      if (!hasPremium && (index === 2 || index === 3)) {
        return WorkoutRingStatus.Locked.code
      }

      // if the game is already played, consider it as complete
      if (slot.lastGamePlay) return WorkoutRingStatus.Complete.code

      // if the game is not played yet, consider it as active
      if (!lastUnplayedGame) {
        lastUnplayedGame = true // only 1 active ring at a time
        return WorkoutRingStatus.Active.code
      }

      return WorkoutRingStatus.Inactive.code
    })()

    return {
      ...slot,
      ringStatus: status,
      ringSvg: <WorkoutSegment status={status} slotNumber={index + 1} />,
      isPlayed: !!slot.lastGamePlay,
    }
  })

  const Rings = mappedSlots.map((slot) => {
    return <React.Fragment key={slot.game.slug}>{slot.ringSvg}</React.Fragment>
  })

  const isWorkoutFinish = (() => {
    if (slug) return false // don't show workout completed if a slug is passed
    return isWorkoutComplete
  })()

  return (
    <WorkoutSegmentContainer>
      <AnimationContainer>
        {isWorkoutFinish ? (
          <CenterIconWorkoutComplete />
        ) : (
          <StyledModeIcon>
            <WorkoutModeIcon mode={mode} />
          </StyledModeIcon>
        )}
        {isWorkoutFinish ? null : Rings}
      </AnimationContainer>
    </WorkoutSegmentContainer>
  )
}

const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-content: center;
`

const WorkoutSegmentContainer = styled.div`
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;

  .fadeInAnimate {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .blinkActiveAnimate {
    color: ${nonTokenColors.neutralWarn95};
    animation: blinkActive 1.4s infinite ease-in-out;
  }

  @keyframes blinkActive {
    0% {
      color: ${nonTokenColors.neutralWarn95};
    }
    50% {
      color: ${nonTokenColors.accentSecondary60};
    }
    100% {
      color: ${nonTokenColors.neutralWarn95};
    }
  }
`

export default WorkoutRing

const WorkoutSegment = ({ status, slotNumber }: { status: keyof typeof WorkoutRingStatus; slotNumber: number }) => {
  const ringConfig = WorkoutRingStatus[status] || {}
  return (
    <WorkoutSegmentSvg
      title='workout-ring'
      className={ringConfig.className || ''}
      style={{
        color: ringConfig.color || nonTokenColors.neutralWarn95,
        // the svg represent 3rd slot visually because it needs to be 0° square (example 10° rotates square takes more space in dom, 0° makes it natural),
        // thus adjust it so that it starts from 1st slot.
        transform: `rotate(${(slotNumber + 2) * 72}deg)`,
        animationDelay: `${slotNumber * 0.1}s`,
        transformOrigin: '50% 50%',
        width: '100%',
        height: '100%',
        position: 'absolute',
      }}
    />
  )
}

const CenterIconCss = css`
  width: 100%;
  height: 100%;
  padding: 18.75%; // padding is taken up by rings, 18.75% of 128px = 24px
  position: absolute;
  animation: fadeIn 1s forwards;
`

const CenterIconWorkoutComplete = styled(WorkoutComplete).attrs({ title: 'workout-complete' })`
  ${CenterIconCss}
`
export const StyledModeIcon = styled.div`
  svg {
    ${CenterIconCss}
  }
`

// usually WorkoutRing is getting calculated based on the slots information available from backend,
// thus we cannot render rings if the workout is not created yet,
// we still need to show 5 slots with active ring for change workout modal,
// thus this component will be able to render the initial state even if we don't have slot in backend
export const WorkoutRingEmpty = ({ workoutMode }: { workoutMode: WorkoutModeTypes }) => {
  const { hasPremium } = useContext(AuthContext)

  const mappedSlots = [1, 2, 3, 4, 5].map((slotNumber: number) => {
    const status = (() => {
      // always blink slot1
      if (slotNumber === 1) {
        return WorkoutRingStatus.Active.code
      }
      // 3rd & 4th slot is locked for free users
      if (!hasPremium && (slotNumber === 2 || slotNumber === 3)) {
        return WorkoutRingStatus.Locked.code
      }
      return WorkoutRingStatus.Inactive.code
    })()

    return {
      slotNumber: slotNumber,
      ringSvg: <WorkoutSegment status={status} slotNumber={slotNumber} />,
    }
  })

  const Rings = mappedSlots.map((slot) => {
    return <React.Fragment key={slot.slotNumber}>{slot.ringSvg}</React.Fragment>
  })

  return (
    <WorkoutSegmentContainer>
      <AnimationContainer>
        <StyledModeIcon>
          <WorkoutModeIcon mode={workoutMode} />
        </StyledModeIcon>
        {Rings}
      </AnimationContainer>
    </WorkoutSegmentContainer>
  )
}
