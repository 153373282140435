import { BodyText2 } from '@lumoslabs/lumosity-storybook'

interface PageDateProps {
  dateString: string
}

const PageDate: React.FC<PageDateProps> = ({ dateString }) => {
  return (
    <div className='mb-2 text-text-subdued'>
      <BodyText2>{dateString}</BodyText2>
    </div>
  )
}

export default PageDate
