import styled from 'styled-components'

const SkeletonLoader = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colorTokens.surface['surface-subdued']} 25%, ${theme.colorTokens.surface['surface-default']} 50%, ${theme.colorTokens.surface['surface-subdued']} 75%)`};
  background-size: 200% 100%;
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  animation: pulse 1.5s infinite;
  @keyframes pulse {
    0% {
      background-position: 200%;
    }
    100% {
      background-position: -200%;
    }
  }
`
export default SkeletonLoader
