// TODO : pick colors from theme once it is introduced in @lumoslabs/lumosity-storybook
// updating the colors here for now, so that we can track all the places where the colors are used with the namespace
export const nonTokenColors = {
  errorButton: '#ea4a67', // colors/interactive/alert-filled-default
  errorButtonHover: '#d43b57', // color/accent/error-50
  alertWarning: '#ffcd3d', // Light/Tertiary Accent
  accentedOrange: '#f05826',
  accentedGrey: '#f2f2f2',
  accentPrimary95: '#FEE5DC',
  borderHover: '#B5B8BA', //  --colors-interactive-interactive-bordered-depressed,
  neutralWarm85: '#DED8D1', // color/neutral/neutral-warm-85
  neutralWarn95: '#f4f2f1', // primitives.neutral.Warm95
  neutralCool90: '#e3e6e8', // primitives.neutral.Cool90
  accentSecondary60: '#ffcd3d', // accent.Secondary60
  accentPrimary50: '#EE4912',
}

// Notes - below colors are not part of tokens,
export const workoutModeHoveColor: Record<WorkoutModeTypes, string> = {
  classic: 'rgba(253, 101, 51, 0.24)',
  math: 'rgba(110, 6, 153, 0.24)',
  language: 'rgba(4, 117, 131, 0.24)',
  favorites: 'rgba(255, 141, 59, 0.24)',
  strengthen: 'rgba(4, 117, 131, 0.24)',
  quick: 'rgba(51, 141, 69, 0.24)',
}
