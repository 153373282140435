import { useCallback } from 'react'

import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'

type TranslateVariables = Parameters<Translate>['1']
type TranslateConfig = Parameters<Translate>['2']

const Namespaces = {
  common: 'common',
  legal: 'legal',
  insights: 'insights',
} as const

/**
 * @deprecated
 * - use useCommonTx instead, the reason is that we are not using this strategy anymore
 * Mental model was to just have one single common.json and treat its first level keys as namespaces,
 * this is worst for treeshaking, perforce and management of the strings even for translators
 *
 * - Another problem was the use of ':' with above strategy, this was creating a confusion while reading a doc for next translate
 * officially the patter is namespace:key, but with above strategy we were trying to ignore the so called namespace (first level key )
 * and programmatically converting it to common.key, check code for more details
 */
export const useTranslationForNamespace = (namespace: string) => {
  const { t } = useTranslation('common')
  /*
  If the key includes `:` this means we are specifying a different namespace
  then the one passed in the initialization of useTranslationForNamespace(),
  so don't append the namespace, otherwise append it
  */
  const translate = useCallback(
    (baseKey: string, vars = {}, config = { fallback: '' }) => {
      const hasColon = baseKey.includes(':')
      const baseKeyString = hasColon ? baseKey.replace(':', '.') : baseKey
      const key = hasColon ? baseKeyString : `${namespace}.${baseKey}`
      // If no fallback is provided, add 'string' as a fallback in case we put the string and context in an object in the locale file
      if (config.fallback === '') {
        config['fallback'] = `${key}.string`
      }

      return t(key, vars, config)
    },
    [namespace, t],
  )

  return translate
}

/**
 * A common translation (Tx) hook using which we is used to create custom hooks for each namespace
 * The benefit of this function signature is that we don't need to write namespace and rootKey again and again
 */
const useNamespaceTx = (namespace: keyof typeof Namespaces, rootKey: string) => {
  const { t } = useTranslation(namespace)

  const translate = useCallback(
    (baseKey: string, vars: TranslateVariables = {}, config: TranslateConfig = { fallback: '' }) => {
      const key = `${rootKey}.${baseKey}`
      if (config.fallback === '') config.fallback = `${namespace}:${key}` // todo: find a better fallback
      return t(key, vars, config)
    },
    [namespace, rootKey, t],
  )

  return translate
}
/**
 * A common translation (Tx) key creator using which we can create custom hooks for each namespace
 * namespace:key is the pattern used by next-translate
 * The benefit of this function signature is that we don't need to write namespace and rootKey again and again
 */
const getNamespaceTxKey = (namespace: keyof typeof Namespaces, rootKey: string) => (key: string) =>
  `${namespace}:${rootKey}.${key}`

/**
 * Check i18n.json for the namespaces URL mapping
 */
export const useLegalTx = (rootKey: string) => {
  return useNamespaceTx(Namespaces.legal, rootKey)
}
export const getLegalTxKey = (rootKey: string) => getNamespaceTxKey(Namespaces.legal, rootKey)

export const useCommonTx = (rootKey: string) => {
  return useNamespaceTx(Namespaces.common, rootKey)
}
export const getCommonTxKey = (rootKey: string) => getNamespaceTxKey(Namespaces.common, rootKey)

export const useInsightsTx = (rootKey: string) => {
  return useNamespaceTx(Namespaces.insights, rootKey)
}
export const getInsightsTxKey = (rootKey: string) => getNamespaceTxKey(Namespaces.insights, rootKey)
