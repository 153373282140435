import { useCommonTx } from '~/hooks/useTranslationForNamespace'
import LockIcon from '~/images/icons/SystemOutlined/Lock.svg'
import UnlockIcon from '~/images/icons/SystemOutlined/Unlock.svg'

interface UnlockButtonProps extends React.HTMLAttributes<HTMLButtonElement> {}

export const UnlockButton = (props: UnlockButtonProps) => {
  const t = useCommonTx('common')
  const { className } = props || {}

  return (
    <button
      {...props}
      className={`h-7 inline-flex items-center text-text-on-interactive rounded-full group bg-accent-success gap-1 p-1 pl-3 pr-2 ${
        className || ''
      }`}
    >
      <span className='text-sm font-semibold uppercase'>{t('buttons.unlock')}</span>
      <UnlockIcon title='unlock' className={`hidden group-hover:block h-5 w-5`} />
      <LockIcon title='lock' className={`block group-hover:hidden h-5 w-5`} />
    </button>
  )
}
