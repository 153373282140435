import { useContext, useState } from 'react'

import { Subheading1 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import { UnlockWorkouts } from '~/components/workout/WorkoutModals'
import { WorkoutModeList } from '~/components/workout/WorkoutModeList'
import { AuthContext } from '~/context/AuthContext'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { UnlockButton } from '~/ui/Button/UnlockButton'

const ModalNames = {
  None: 'None',
  UnlockWorkouts: 'UnlockWorkouts',
}

export const WorkoutModes = () => {
  const { hasPremium } = useContext(AuthContext)
  const t = useTranslationForNamespace('workout')
  const [activeModal, setActiveModal] = useState(ModalNames.None)

  const UnlockModalClicks = {
    onShow: () => setActiveModal(ModalNames.UnlockWorkouts),
    onCancel: () => setActiveModal(ModalNames.None),
  }

  return (
    <Container>
      <HeaderSection>
        <Subheading1>{t('modeTitle')}</Subheading1>
        {!hasPremium && <UnlockButton onClick={UnlockModalClicks.onShow} />}
      </HeaderSection>
      <WorkoutModeList />

      {activeModal === ModalNames.UnlockWorkouts && <UnlockWorkouts onCancel={UnlockModalClicks.onCancel} />}
    </Container>
  )
}

const Container = styled.div`
  width: min(100%, 592px);
  margin-top: 40px;
`

const HeaderSection = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
