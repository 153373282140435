import { useEffect, useRef } from 'react'

export const useEffectOnce = (callback: () => Promise<void> | void) => {
  const didRun = useRef(false) // Ref to track if the callback has been executed

  useEffect(() => {
    async function executeCallback() {
      if (didRun.current) return // If the callback has already run, do nothing
      didRun.current = true // Mark as having run immediately
      await callback() // Execute the callback
    }
    executeCallback()
  }, [callback]) // Run effect if callback changes
}
