import React from 'react'

import { Heading3, Heading2, BodyText2, BodyText1 } from '@lumoslabs/lumosity-storybook'
import Head from 'next/head'
import Link from 'next/link'
import styled from 'styled-components'

import Footer from '~/components/Footer'
import TopNav from '~/components/TopNav'
import { useGetUserQuery } from '~/graphql/generated/schema'
import useLocalDate from '~/hooks/useLocalDate'
import useLocale from '~/hooks/useLocale'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import AppStoreBadge from '~/images/MobileApps/app-store-badge.svg'
import GooglePlayBadge from '~/images/MobileApps/google-play-badge.svg'
import MobileDeviceImage from '~/images/MobileApps/mobile-apps.svg'

const MobileDevicesInterstitial = (): JSX.Element => {
  const t = useTranslationForNamespace('common')
  const { dateFormatted } = useLocalDate()
  const { minNumberOfGamesForLocale } = useLocale()
  const { data: userQueryData } = useGetUserQuery()
  const name = userQueryData?.me?.firstName
  const greeting = t(`interstitials.mobileDevices.greeting`, { name: name })
  const isAndroidOS = navigator.userAgent.match(/Android/i)
  const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i)
  const isUnknownOS = !isAndroidOS && !isIOS
  const showGooglePlayStoreLink = isAndroidOS || isUnknownOS
  const showAppleAppStoreLink = isIOS || isUnknownOS

  return (
    <>
      <Head>
        <title>{t('interstitials.mobileDevices.title')}</title>
      </Head>
      <TopNav />
      <PageContent>
        <StyledBodyText2>{dateFormatted}</StyledBodyText2>
        <StyledHeading2>{greeting}</StyledHeading2>
        <StyledHeading3 as='h3'>{t('interstitials.mobileDevices.header')}</StyledHeading3>
        <BadgeRow>
          {showGooglePlayStoreLink && (
            <BadgeContainer>
              {/*TODO: localize the SVG for all marketplaces */}
              <Link href='https://play.google.com/store/apps/details?id=com.lumoslabs.lumosity' passHref={true}>
                <GooglePlayBadge title='google-play-badge' width='100%' height='100%' />
              </Link>
            </BadgeContainer>
          )}
          {showAppleAppStoreLink && (
            <BadgeContainer>
              {/*TODO: localize the SVG for all marketplaces */}
              <Link href='https://apps.apple.com/app/lumosity-brain-training/id577232024' passHref={true}>
                <AppStoreBadge title='app-store-badge' width='100%' height='100%' />
              </Link>
            </BadgeContainer>
          )}
        </BadgeRow>
        <MobileDeviceImage title='mobile-apps' width='100%' height='100%' />
        <StyledBodyText1>
          {t('interstitials.mobileDevices.body', { minNumberOfGames: minNumberOfGamesForLocale })}
        </StyledBodyText1>
      </PageContent>
      <Footer />
    </>
  )
}

const BadgeContainer = styled.div`
  width: 150px;
  margin-right: 15px;
  cursor: pointer;
`

const BadgeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    justify-content: space-around;
  }
`
const StyledHeading2 = styled(Heading2)`
  line-height: 48px;
  display: block;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    text-align: center;
  }
`
const StyledHeading3 = styled(Heading3)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    text-align: center;
  }
`
const StyledBodyText2 = styled(BodyText2)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    text-align: center;
    margin: 0 auto;
  }
`
const StyledBodyText1 = styled(BodyText1)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    text-align: center;
  }
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    align-items: center;
    justify-content: center;
  }
  margin: 48px 33px;
`
export default MobileDevicesInterstitial
