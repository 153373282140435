import { useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { PRE_FIT_TEST_PATHS } from '~/constants'
import { useGetFitTestProgressQuery } from '~/graphql/generated/schema'
import { currentUserVar, invitationVar } from '~/graphql/reactive-vars'

export function useFitTestRedirect() {
  const router = useRouter()
  const invitationFromStorage = useReactiveVar(invitationVar)
  const currentUser = useReactiveVar(currentUserVar)
  const currentPath = router.pathname

  let isFitTestComplete = false

  const isCurrentPathAllowedBeforeFitTest = PRE_FIT_TEST_PATHS.some((path) => currentPath.startsWith(path))

  const { data, loading } = useGetFitTestProgressQuery({ skip: isCurrentPathAllowedBeforeFitTest })

  if (data) {
    // Skip Fit Test for migrated users from Flagship
    // its hard to tell if users from flagship were genuinely new/un-onboarded, and it was preferable not to unnecessarily onboard flagship users who didn't need it
    // for L2 we'd rather miss a fit test in the rare case of a new user who did nothing in flagship than annoy an old time user from before fit tests
    if (!data.me?.originL2) {
      isFitTestComplete = true
    } else {
      // User has percentiles for at least 3 Fit Test games
      const fitTestGamePercentilesCount = data.me.fitTest.percentiles?.length || 0
      isFitTestComplete = fitTestGamePercentilesCount >= 3
    }
  }

  // Logic to redirect users to the Onboarding Flow if they haven't completed the Fit Test.
  // Onboarding SurveyFlow has logic to skip completed questions and direct to the Fit Test.

  // The currentPath is allowed if the current path is included in the list of paths you can go to
  // regardless of your fit test completion status OR if we know that the user has completed their fit test
  const isPathAllowed = isCurrentPathAllowedBeforeFitTest || isFitTestComplete
  const [cookies] = useCookies()
  // Google Click ID cookie is like GCL.1676670743.EAIaIQobChMIoqjC8aad_QIVexGtBh2MEAYzEAAYASAAEgKP5vD_BwE
  const gclidCookie = cookies['_gcl_aw']
  // Get the click ID itself
  const gclid = gclidCookie?.split('.')[2]
  // If there's a gclidCookie, add it to the params of the redirect link
  const gclidParams = gclidCookie?.length > 0 ? `?gclid=${gclid}` : ''

  useEffect(() => {
    // TODO: This can be removed once we implement redirects with new auth system
    const hasPendingInvitation = invitationFromStorage?.hasAcceptedInvite === true
    if (hasPendingInvitation && currentUser?.id && !isCurrentPathAllowedBeforeFitTest) {
      const invitationPath = `/family-invitation/${invitationFromStorage.invitationId}`
      if (currentPath !== invitationPath) {
        router.push(invitationPath)
      }
    }
    if (!hasPendingInvitation && !loading && !isPathAllowed && currentUser?.id) {
      router.push(`/onboard${gclidParams}`)
    }
  }, [
    router,
    loading,
    gclidParams,
    isPathAllowed,
    currentUser?.id,
    invitationFromStorage,
    currentPath,
    isCurrentPathAllowedBeforeFitTest,
  ])

  return {
    mayRedirectToOnboarding: !isPathAllowed,
  }
}
