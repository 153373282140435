// https://tailwindcss.com/docs/responsive-design
export const screens = {
  '3xs': 320, //  mobile small
  '2xs': 375, //  mobile
  xs: 425, //  mobile large
  sm: 640, // tablet small
  md: 768, // tablet or table portrait
  lg: 1024, // tablet large or tablet landscape or desktop small
  xl: 1280, // desktop
  '2xl': 1536, // desktop large
} as const

export type ScreenTypeKeys = keyof typeof screens

// convert screens to string pixels, used in tailwindcss
export const screensInPx: Record<ScreenTypeKeys, string> = Object.keys(screens).reduce(
  (acc: Record<string, string>, key) => {
    acc[key] = `${screens[key as ScreenTypeKeys]}px`
    return acc
  },
  {},
)
