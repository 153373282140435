import { useMemo, useState } from 'react'

import { useCookies } from 'react-cookie'
import { getOryFrontendClient } from 'src/clients/oryClient'

import PageLoader from '~/components/ui/PageLoader'
import { LUMOSITY_USER_COOKIE } from '~/constants'
import { usePostLogin } from '~/hooks/useAuth'
import { useEffectOnce } from '~/hooks/useEffectOnce'

// kratos session cookie is http only and secure, so we can't access it from the client side
// to know if the user is authenticated or not, we have 2 ways:
// 1. call `me` query to check if the user is authenticated or not, internally it will check session validity from kratos
// 2. We can directly call toSession to check the validity of the session from kratos
// point 2 is faster and more efficient, so we are using that here

// When will this happen? - OptimizeBox is responsible to crete kratos session & redirect to L2,
// thus to make the system work, we need to create lumosityUser cookie from the kratos session
export const LumosityUserCookieCreator: React.FC = ({ children }) => {
  // by default we will wait for the process to complete, until useEffectOnce make it false
  // once the flag is false, we will render the children
  const [isWaitingForSession, setIsWaitingForSession] = useState<boolean>(true)
  const { processOrySession } = usePostLogin()
  const [{ [LUMOSITY_USER_COOKIE]: lumosityUserCookie }] = useCookies([LUMOSITY_USER_COOKIE])
  const l2UserCookie = useMemo(() => lumosityUserCookie, [lumosityUserCookie])

  // useEffectOnce means this function should get executed only once on the first reload of the page
  // for the subsequent route changes, we should not execute this.
  useEffectOnce(() => {
    // if the user is already authenticated, we don't need to wait for the session
    if (l2UserCookie) {
      setIsWaitingForSession(false)
      return
    }

    ;(async () => {
      const oryFrontendClient = getOryFrontendClient()
      const response = await oryFrontendClient.toSession()
      const session = response.data
      await processOrySession({ orySession: session, onError: () => {} })
    })()
      .catch(() => {
        // ignore error
      })
      .finally(() => {
        setIsWaitingForSession(false)
      })
  })

  if (isWaitingForSession) {
    return <PageLoader />
  }

  return <>{children}</>
}
