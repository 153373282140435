import { useCallback, useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import type { ContentCards, Card } from '@braze/web-sdk'
import getConfig from 'next/config'

import type { MarketingContext } from '~/context/MarketingContext'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import logger from '~/utils/logger'

const { publicRuntimeConfig } = getConfig()

// Used for Braze tracking
export const useInitializeMarketingFramework = () => {
  const BRAZE_SDK_KEY = publicRuntimeConfig.marketing.sdkKey
  const BRAZE_SDK_ENDPOINT = publicRuntimeConfig.marketing.endpoint

  const currentUser = useReactiveVar(currentUserVar)
  const [marketingUser, setMarketingUser] = useState<MarketingContext['marketingUser']>(null)
  const [callbacks, setCallbacks] = useState<{
    handleBrazeAction: MarketingContext['handleBrazeAction']
    logCustomMarketingEvent: MarketingContext['logCustomMarketingEvent']
    logCardClickEvent: MarketingContext['logCardClickEvent']
    logCardImpressionEvent: MarketingContext['logCardImpressionEvent']
    logCardDismissalEvent: MarketingContext['logCardDismissalEvent']
  }>({
    handleBrazeAction: () => {},
    logCustomMarketingEvent: () => false,
    logCardClickEvent: () => true,
    logCardImpressionEvent: () => true,
    logCardDismissalEvent: () => true,
  })

  const [contentCards, setContentCards] = useState<Card[]>([])

  const initializeMarketingFramework = useCallback(
    async (userId) => {
      if (process.env.NODE_ENV === 'development' && !BRAZE_SDK_KEY) {
        logger.info('No BRAZE_SDK_KEY detected. Braze will not initialize.')
        return
      }
      // dynamically import required methods on client https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#ssr
      // uses Webpack magic comments https://webpack.js.org/api/module-methods/#magic-comments
      const {
        initialize,
        openSession,
        automaticallyShowInAppMessages,
        handleBrazeAction,
        changeUser,
        getUser,
        logCustomEvent,
        logCardClick,
        logCardImpressions,
        logCardDismissal,
        requestContentCardsRefresh,
        subscribeToContentCardsUpdates,
        getCachedContentCards,
      } = await import(
        /* webpackExports: ["initialize", "openSession", "automaticallyShowInAppMessages", "handleBrazeAction", "changeUser", "getUser", "logCustomEvent", "logCardClick", "logCardDismissal", "requestContentCardsRefresh", "subscribeToContentCardsUpdates", "getCachedContentCards"] */
        '@braze/web-sdk'
      )
      initialize(BRAZE_SDK_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT,
        enableLogging: true, // TODO: switch this back to ENABLE_BRAZE_LOGGING after debugging is complete
        allowUserSuppliedJavascript: true,
        minimumIntervalBetweenTriggerActionsInSeconds: 1,
      })
      // enable in-app messages - must be called before `openSession()`
      automaticallyShowInAppMessages()

      // track content card updates
      subscribeToContentCardsUpdates((cards: ContentCards) => {
        logger.info('Received content cards from Braze...', cards)
        setContentCards(cards.cards)
      })
      // eagerly set content cards to an initial value
      const cards = getCachedContentCards()
      setContentCards(cards.cards)

      // set the braze user with the L2 ID from the auth JWT
      changeUser(userId)

      const user = getUser()

      // set brazeUser -- necessary for setting attributes
      if (user) {
        setMarketingUser(user)
      }

      requestContentCardsRefresh()

      logger.info('Setting up Braze callbacks...', handleBrazeAction)
      setCallbacks({
        logCustomMarketingEvent: logCustomEvent,
        logCardClickEvent: (card: Card, forContentCards?: boolean) => {
          logger.info('Braze card clicked', card)
          return logCardClick(card, forContentCards)
        },
        logCardImpressionEvent: (card: Card, forContentCards?: boolean) => {
          logger.info('Braze card impression', card)
          return logCardImpressions?.([card], forContentCards)
        },
        logCardDismissalEvent: (card: Card) => {
          logger.info('Braze card dismissed', card)
          return logCardDismissal(card)
        },
        handleBrazeAction: (url: string, openLinkInNewTab?: boolean) => {
          logger.info('Braze action handled', url)
          handleBrazeAction(url, openLinkInNewTab)
        },
      })

      openSession()
    },
    [BRAZE_SDK_ENDPOINT, BRAZE_SDK_KEY],
  )

  useEffect(() => {
    if (currentUser?.id) {
      initializeMarketingFramework(currentUser.id)
    }
  }, [currentUser, initializeMarketingFramework])

  return {
    marketingUser,
    contentCards,
    setContentCards,
    ...callbacks,
  }
}
