import useLocale from '~/hooks/useLocale'

const useLocalDate = (date?: number) => {
  const currentDate = date ? new Date(date) : new Date()
  const { locale } = useLocale()
  const hour = currentDate.getHours()

  const dateFormatted = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  }).format(currentDate)

  const greetingKey = () => {
    if (hour < 12) {
      return 'goodMorning'
    } else if (hour < 17) {
      return 'goodAfternoon'
    } else {
      return 'goodEvening'
    }
  }

  return {
    hour,
    dateFormatted,
    greetingKey,
  }
}

export default useLocalDate

export type UseLocalDateFormatType = {
  date: number // unix time
}
export const useLocalDateFormat = (props: UseLocalDateFormatType) => {
  const date = new Date(props.date)
  const { locale } = useLocale()

  const dateFormatted = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(date)

  return {
    dateFormatted,
  }
}
