import { screens } from '~/styles/breakpoints'

/**
 * @deprecated - use src/styles/breakpoints.ts
 * few of the breakpoints are not standard
 */
export const breakpoints = {
  mobileSmall: screens['3xs'],
  mobile: screens['2xs'],
  mobileLarge: 576,
  tablet: screens['md'],
  tabletMedium: 850,
  tabletLarge: screens['lg'],
  desktopSmall: 992,
  desktop: screens['xl'],
} as const

export const mediaQuery = {
  maxWidth: {
    mobileSmall: `@media (max-width:${breakpoints.mobileSmall}px)`,
    mobile: `@media (max-width:${breakpoints.mobile}px)`,
    mobileLarge: `@media (max-width:${breakpoints.mobileLarge}px)`,
    tablet: `@media (max-width:${breakpoints.tablet}px)`,
    tabletMedium: `@media (max-width:${breakpoints.tabletMedium}px)`,
    tabletLarge: `@media (max-width:${breakpoints.tabletLarge}px)`,
    desktopSmall: `@media (max-width:${breakpoints.desktopSmall}px)`,
    desktop: `@media (max-width:${breakpoints.desktop}px)`,
  },
  // min width values slightly higher than maxWidth to avoid conflicts
  minWidth: {
    mobileSmall: `@media (min-width:${breakpoints.mobileSmall + 0.2}px)`,
    mobile: `@media (min-width:${breakpoints.mobile + 0.2}px)`,
    mobileLarge: `@media (min-width:${breakpoints.mobileLarge + 0.2}px)`,
    tablet: `@media (min-width:${breakpoints.tablet + 0.2}px)`,
    tabletMedium: `@media (min-width:${breakpoints.tabletMedium + 0.2}px)`,
    tabletLarge: `@media (min-width:${breakpoints.tabletLarge + 0.2}px)`,
    desktopSmall: `@media (min-width:${breakpoints.desktop + 0.2}px)`,
    desktop: `@media (min-width:${breakpoints.desktop + 0.2}px)`,
  },
}
